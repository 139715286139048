import { Injectable } from '@angular/core';
import { Keyword } from '@models/shared/keyword.model';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { TargetGroup } from '@models/shared/target-group.model';
import { GiphyResponse } from '@models/shared/giphy-response.model';
import { UnsplashResponse } from '@models/shared/unsplash-response.model';
import { UserLimitType } from '@models/users/user-limit-type.enum';
import { UserLimit } from '@models/users/user-limit.model';
import { map } from 'rxjs/operators';
import { SaveKeywordRequest } from '@models/shared/dto/save-keyword.request';
import { SaveTargetGroupRequest } from '@models/shared/dto/save-target-group.request';

@Injectable()
export class SharedService {
  constructor(private api: ApiService) {}

  getKeywords(): Observable<Keyword[]> {
    return this.api.get(`${env.api}/keywords`);
  }

  saveKeyword(req: SaveKeywordRequest): Observable<Keyword> {
    return this.api
      .post(`${env.api}/keywords`, req)
      .pipe(map((it) => new Keyword(it)));
  }

  getTargetGroups(eventCategoryIds?: number[]): Observable<TargetGroup[]> {
    const params = new Map<string, any>();

    if (eventCategoryIds) {
      params.set('eventCategoryIds', eventCategoryIds);
    }

    return this.api.get(`${env.api}/target-groups`, params);
  }

  saveTargetGroup(req: SaveTargetGroupRequest): Observable<TargetGroup> {
    return this.api
      .post(`${env.api}/target-groups`, req)
      .pipe(map((it) => new TargetGroup(it)));
  }

  getGiphyGifs(searchTerm?: string): Observable<GiphyResponse> {
    const params = new Map<string, any>();

    if (searchTerm && searchTerm !== '') {
      params.set('searchTerm', searchTerm);
    }

    return this.api.get(`${env.api}/giphy-gifs`, params);
  }

  getUnsplashImages(searchTerm?: string): Observable<UnsplashResponse> {
    const params = new Map<string, any>();

    if (searchTerm && searchTerm !== '') {
      params.set('searchTerm', searchTerm);
    }

    return this.api.get(`${env.api}/unsplash-images`, params);
  }

  getUserLimit(limitType: UserLimitType): Observable<UserLimit> {
    const params = new Map<string, any>();

    if (limitType) {
      params.set('limitType', limitType);
    }

    return this.api
      .get(`${env.api}/user-limit`, params)
      .pipe(map((it) => new UserLimit(it)));
  }
}
