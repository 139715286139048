import { ClassData } from '@models/class-data';
import { EventCategory } from '@models/event-categories/event-category.model';
export class Keyword {
  id: number;

  name: string;

  categories?: EventCategory[];

  constructor(json: ClassData<Keyword>) {
    this.id = +json.id;

    this.name = json.name;

    if (json.categories) {
      this.categories = this.mapEventCategories(json.categories);
    }
  }

  private mapEventCategories(categories: EventCategory[]): EventCategory[] {
    return categories.map((it) => new EventCategory(it));
  }

  getEventCategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    const mainCategories = this.categories
      .filter((category) => !category.parentCategoryId)
      .map((category) => category.id);

    return [...new Set(mainCategories)];
  }

  getEventSubcategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    return this.categories
      .filter(
        (category) =>
          category.parentCategoryId && category.parentCategoryId > 0,
      )
      .map((category) => category.id);
  }
}
