import { ClassData } from '@models/class-data';
import { UserLimitType } from './user-limit-type.enum';

export class UserLimit {
  id?: number;

  userId?: number;
  ip?: string;

  limitType: UserLimitType;

  counter: number;
  limit: number;

  expirationDate?: Date;

  constructor(json: ClassData<UserLimit>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }
    if (json.userId !== undefined) {
      this.userId = +json.userId;
    }
    if (json.ip) {
      this.ip = json.ip;
    }

    this.limitType = json.limitType;

    this.counter = +json.counter;
    this.limit = +json.limit;

    if (json.expirationDate) {
      this.expirationDate = new Date(json.expirationDate);
    }
  }

  get limitLeft(): number {
    return this.limit - this.counter;
  }

  limitReached(): boolean {
    return this.counter === this.limit;
  }
}
