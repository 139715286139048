import { ClassData } from '@models/class-data';
import { EventCategory } from '@models/event-categories/event-category.model';

export class TargetGroup {
  id: number;

  name: string;
  description: string;
  toneOfVoice: string;
  eventMaterialsImageStyle: string;
  visualElementStyle: string;

  categories?: EventCategory[];

  constructor(json: ClassData<TargetGroup>) {
    this.id = +json.id;

    this.name = json.name;
    this.description = json.description;
    this.toneOfVoice = json.toneOfVoice;
    this.eventMaterialsImageStyle = json.eventMaterialsImageStyle;
    this.visualElementStyle = json.visualElementStyle;

    if (json.categories) {
      this.categories = this.mapEventCategories(json.categories);
    }
  }

  private mapEventCategories(categories: EventCategory[]): EventCategory[] {
    return categories.map((it) => new EventCategory(it));
  }

  getEventCategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    const mainCategories = this.categories
      .filter((category) => !category.parentCategoryId)
      .map((category) => category.id);

    return [...new Set(mainCategories)];
  }

  getEventSubcategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    return this.categories
      .filter(
        (category) =>
          category.parentCategoryId && category.parentCategoryId > 0,
      )
      .map((category) => category.id);
  }
}
